import React from 'react'
import { Button, Typography } from '@wicadu/arepa/ui'
import styled from '@emotion/styled'
import PropTypes, { InferProps } from 'prop-types'

const propTypes = {
  text: PropTypes.string.isRequired,
  toActionText: PropTypes.string.isRequired,
  toAction: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool
}

type Props = InferProps<typeof propTypes>

function TextWithCallToAction ({ text, toActionText, toAction, ...props }: Props) {
  return (
    <Container>
      <ContainerTypography>
        <Typography type='description' size={14} style={{ margin: 0 }}>{text}</Typography>
      </ContainerTypography>
      <Action type='link' onClick={toAction} {...props}>{toActionText}</Action>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`

const ContainerTypography = styled.div`
  margin-right: 0.5rem;
`

const Action = styled(Button)`
  width: auto !important;
`

TextWithCallToAction.propTypes = propTypes

export default TextWithCallToAction
